import { Controller } from "@hotwired/stimulus"
import apolloClient from "@/api/client"
import SentryReact from "@/common/sentry_client"
import { graphql } from "@/api"

const quoteLinkSendDoc = graphql(`
  mutation QuoteLinkSend($id: ID!) {
    quoteLinkSend(id: $id) {
      success
    }
  }
`)

export default class extends Controller {
  static values = { orderId: String }

  declare orderIdValue: string
  declare hasOrderIdValue: boolean

  static targets = ["banner", "mainContent", "successText"]

  declare bannerTarget: HTMLDivElement
  declare hasBannerTarget: boolean

  declare mainContentTarget: HTMLDivElement
  declare hasMainContentTarget: boolean

  declare successTextTarget: HTMLDivElement
  declare hasSuccessTextTarget: boolean

  async submit(e: Event) {
    e.preventDefault()
    const button = e.target as HTMLInputElement
    button.classList.add("disabled")

    try {
      const result = await apolloClient.mutate({
        mutation: quoteLinkSendDoc,
        variables: { id: this.orderIdValue },
      })

      if (result.data?.quoteLinkSend?.success === true) {
        button.classList.add("hidden")

        if (this.hasMainContentTarget && this.hasSuccessTextTarget && this.hasBannerTarget) {
          this.mainContentTarget.classList.add("hidden")
          this.successTextTarget.classList.remove("hidden")
          this.bannerTarget.classList.remove("warning")
          this.bannerTarget.classList.add("success")
        }
      }
    } catch (error: any) {
      this.bannerTarget.classList.add("error")
      button.classList.remove("disabled")

      SentryReact.captureMessage(error)
    }
  }
}
