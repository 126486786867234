import React from "react"
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom"
import * as Sentry from "@sentry/react"
import env from "./env"

const dsn = "https://d0e290b7a0786fcc037fc6771aee12a0@sentry.skyltmax.dev/2"
const tracesSampleRate = 1.0
const replaysSessionSampleRate = env.admin ? 1.0 : 0.5

const setReplayCookie = (value: string) => {
  document.cookie = "sm_replay_id" + "=" + encodeURIComponent(value) + "; path=/"
}

const deleteReplayCookie = () => {
  document.cookie = "sm_replay_id=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/"
}

if (!import.meta.env.SSR) {
  deleteReplayCookie()
}

let replayCookie: string | null = null

if (env.PRODUCTION || env.STAGING) {
  Sentry.init({
    dsn,
    tunnel: "https://sentry-tunnel.skyltmax.dev/tunnel",
    environment: env.RAILS_ENV,
    release: env.VERSION,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        beforeAddRecordingEvent: event => {
          const replayId = Sentry.getReplay()?.getReplayId()

          // Only set cookie once per session, unless replayId changes
          if (replayId && (!replayCookie || replayCookie !== replayId)) {
            setReplayCookie(replayId)
            replayCookie = replayId
          }

          return event
        },
      }),
    ],
    initialScope(scope) {
      const user: Sentry.User = { ip_address: "{{auto}}" }

      if (env.admin) {
        user.admin = env.admin
        scope.setTags({ "admin.id": env.admin })
      }

      if (env.customer) {
        user.id = env.customer
        scope.setTags({ "customer.id": env.customer })
      }

      scope.setUser(user)

      return scope
    },
    tracesSampleRate,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate: 1.0,
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // https://blog.sentry.io/2017/03/27/tips-for-reducing-javascript-error-noise
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    allowUrls: [
      /https?:\/\/(.*\.)?signomatic\.co\.uk/,
      /https?:\/\/(.*\.)?signomatic\.com/,
      /https?:\/\/(.*\.)?schildermaxe\.de/,
      /https?:\/\/(.*\.)?rotumax\.es/,
      /https?:\/\/(.*\.)?kylttimax\.fi/,
      /https?:\/\/(.*\.)?skiltmax\.dk/,
      /https?:\/\/(.*\.)?skiltmax\.no/,
      /https?:\/\/(.*\.)?schildermaxe\.at/,
      /https?:\/\/(.*\.)?signomatic\.ie/,
      /https?:\/\/(.*\.)?signomatic\.com\.au/,
      /https?:\/\/(.*\.)?skyltmax\.se/,
      /https?:\/\/(.*\.)?bordenmax\.nl/,
      /https?:\/\/(.*\.)?plaqueomatic\.fr/,
      /https?:\/\/(.*\.)?szyldmax\.pl/,
      /https?:\/\/(.*\.)?signomatic\.be/,
      /https?:\/\/(.*\.)?signomatic\.ch/,
      /https?:\/\/(.*\.)?cartellimax\.it/,
      /https?:\/\/(.*\.)?signomatic\.ee/,
      /https?:\/\/(.*\.)?signomatic\.co\.nz/,
      /https?:\/\/(.*\.)?znaceni-max\.cz/,
      /https?:\/\/(.*\.)?skyltmax\.io/,
      /https?:\/\/(.*\.)?devbot\.run/,
    ],
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // edge ios crap
      "instantSearchSDKJSBridgeClearHighlight",
      "Identifier 'change_ua' has already been declared",
      "Cannot redefine property: BetterJsPop",
      "from accessing a cross-origin frame",

      // our stuff
      // ios canvas memory limit
      "this._cacheContext.setTransform",
      "this._cacheContext.translate",
      "this.canvas.getRetinaScaling",
      "evaluating 'n.scale'",
      "reading 'getRetinaScaling'",
      "Text content does not match server-rendered HTML",
      "Hydration failed because the server rendered HTML didn't match the client",
      "Minified React error #425", // Text content does not match server-rendered HTML.
      "Minified React error #418", // Hydration failed because the server rendered HTML didn't match the client.
      "Minified React error #421", // This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering. The usual way to fix this is to wrap the original update in startTransition.
      "Minified React error #422", // There was an error while hydrating but React was able to recover by instead client rendering from the nearest Suspense boundary.
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /pagead\/viewthroughconversion/i,
    ],
  })
}

const SentryReact = Sentry

export default SentryReact
