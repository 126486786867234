// figures out the currently used global object for non-browser environments (node, SSR)
const getGlobal = (): Window & typeof globalThis => {
  if (typeof window !== "undefined") {
    return window
  }

  if (typeof globalThis !== "undefined") {
    return globalThis as any
  }

  if (typeof self !== "undefined") {
    return self
  }

  if (typeof global !== "undefined") {
    return global as any
  }

  return {} as any
}

const currentGlobal = getGlobal()

export default currentGlobal
