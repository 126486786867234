import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { scope: String, initTrackingId: String, showSwissPopup: String }
  static targets = [
    "invoicesCheckbox",
    "invoicesCheckboxRed",
    "sendLink",
    "sendLinkSwiss",
    "reservedCheckbox",
    "reservedButton",
    "trackingId",
    "trackingIdSubmit",
  ]

  declare scopeValue: string
  declare hasScopeValue: boolean

  declare initTrackingIdValue: string
  declare hasInitTrackingIdValue: boolean

  declare showSwissPopupValue: string
  declare hasShowSwissPopupValue: boolean

  declare invoicesCheckboxTargets: HTMLInputElement[]

  declare invoicesCheckboxRedTarget: HTMLInputElement
  declare hasInvoicesCheckboxRedTarget: boolean

  declare reservedCheckboxTarget: HTMLInputElement
  declare hasReservedCheckboxTarget: boolean

  declare trackingIdTarget: HTMLInputElement
  declare hasTrackingIdTarget: boolean

  declare trackingIdSubmitTarget: HTMLInputElement
  declare hasTrackingIdSubmitTarget: boolean

  declare sendLinkTarget: HTMLLinkElement
  declare hasSendLinkTarget: boolean

  declare sendLinkSwissTarget: HTMLLinkElement
  declare hasSendLinkSwissTarget: boolean

  declare reservedButtonTarget: HTMLLinkElement
  declare hasReservedButtonTarget: boolean

  connect() {
    if (this.hasSendLinkTarget && ["invoices_post", "norway_send", "switzerland_send"].includes(this.scopeValue)) {
      this.sendLinkTarget.classList.add("!hidden")
    }

    if (this.hasShowSwissPopupValue && this.showSwissPopupValue == "true") {
      window.dispatchEvent(
        new CustomEvent("openAdminPopup", {
          detail: {
            options: JSON.stringify({
              header:
                "Order not found, please check that the label is Swiss post and that the order is packed in SignAdmin.",
              button1: { text: "Close", close: true, focus: true },
            }),
          },
        })
      )
    }
  }

  invoicesPrinted(e?: Event) {
    e?.preventDefault()

    const checkboxesUnChecked = this.invoicesCheckboxTargets.some(checkbox => {
      return checkbox.checked === false
    })

    const allChecked =
      this.scopeValue === "invoices_post"
        ? !checkboxesUnChecked && this.invoicesCheckboxRedTarget.checked
        : !checkboxesUnChecked

    if (allChecked) {
      this.sendLinkTarget.classList.remove("!hidden")
    } else {
      this.sendLinkTarget.classList.add("!hidden")
    }
  }

  swissReadyToSend(e?: Event) {
    e?.preventDefault()

    if (this.reservedCheckboxTarget.checked && !this.unsavedTrackingNumber()) {
      this.setDisabled(this.sendLinkSwissTarget, false)
    } else {
      this.setDisabled(this.sendLinkSwissTarget, true)
    }
  }

  weightChange(e?: Event) {
    e?.preventDefault()

    if ((e?.target as HTMLInputElement).value == "") {
      this.setDisabled(this.reservedButtonTarget, true)
    } else {
      this.setDisabled(this.reservedButtonTarget, false)
    }
  }

  trackingIdChange(e?: Event) {
    e?.preventDefault()

    if (this.initTrackingIdValue != this.trackingIdTarget.value) {
      this.setDisabled(this.trackingIdSubmitTarget, false)
    } else {
      this.setDisabled(this.trackingIdSubmitTarget, true)
    }

    this.swissReadyToSend()
  }

  setDisabled(el: HTMLElement, disabled: boolean) {
    if (disabled) {
      el.setAttribute("disabled", "")
      el.classList.add("pointer-events-none")
    } else {
      el.removeAttribute("disabled")
      el.classList.remove("pointer-events-none")
    }
  }

  unsavedTrackingNumber() {
    if (!this.hasInitTrackingIdValue || this.initTrackingIdValue.trim() == "") {
      return true
    }

    if (this.hasInitTrackingIdValue && this.hasTrackingIdTarget) {
      return this.initTrackingIdValue != this.trackingIdTarget.value
    }

    return false
  }
}
