import api from "@/common/util/api"

const isBefore = (el1: HTMLElement, el2: HTMLElement) => {
  if (el2.parentNode === el1.parentNode) {
    for (let cur = el1.previousSibling; cur && cur.nodeType !== 9; cur = cur.previousSibling) {
      if (cur === el2) {
        return true
      }
    }
  }

  return false
}

const queryOrder = (container: HTMLElement) => {
  return Array.from(container.querySelectorAll(".sortable")).map((e: HTMLElement) => e.dataset.id)
}

const saveOrder = (container: HTMLElement, updateUrl?: string) => {
  if (!updateUrl) {
    return
  }

  container.classList.add("saving")
  const order = queryOrder(container)

  api
    .put(updateUrl, { order })
    .then(() => {
      container.classList.remove("saving")
    })
    .catch(e => {
      container.classList.remove("saving")
      alert(e)
    })
}

export const draggable = (container: HTMLElement, updateUrl?: string) => {
  let dragTarget: HTMLElement | null

  const sortables: HTMLElement[] = []
  const unsortables: Array<[HTMLElement, number]> = []

  const items = Array.from(container.querySelectorAll<HTMLElement>(`.sortable, .unsortable`))

  for (let index = 0; index < items.length; index++) {
    const element = items[index]

    if (element.classList.contains("sortable")) {
      sortables.push(element)
    }

    if (element.classList.contains("unsortable")) {
      unsortables.push([element, index])
    }
  }

  for (const el of sortables) {
    el.addEventListener("dragstart", (e: DragEvent) => {
      if (e.dataTransfer) {
        e.dataTransfer.effectAllowed = "move"
        e.dataTransfer.setData("nothing", "nil")
      }
      dragTarget = e.target as HTMLElement
    })

    el.addEventListener("dragover", (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()

      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = "move"
      }

      const currentTarget = e.currentTarget as HTMLElement

      if (currentTarget === dragTarget) {
        return
      }

      if (!dragTarget) {
        return
      }

      if (isBefore(dragTarget, currentTarget)) {
        currentTarget.parentNode?.insertBefore(dragTarget, currentTarget)
      } else {
        currentTarget.parentNode?.insertBefore(dragTarget, currentTarget.nextSibling)
      }

      const updatedSortables = Array.from(container.querySelectorAll(".sortable"))

      for (const unsortable of unsortables) {
        const before = updatedSortables[unsortable[1]]
        currentTarget.parentNode?.insertBefore(unsortable[0], before)
      }
    })

    el.addEventListener("dragend", () => {
      saveOrder(container, updateUrl)
    })

    el.addEventListener(
      "drop",
      (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        dragTarget = null
      },
      false
    )
  }
}
