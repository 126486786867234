import { Controller } from "@hotwired/stimulus"

interface TriggerParams {
  modal: string
}

interface TwuiModalController {
  open: () => void
  close: () => void
}

export default class extends Controller implements TwuiModalController {
  static values = {
    open: Boolean,
  }

  declare openValue: boolean
  declare hasOpenValue: boolean

  connect() {
    if (this.hasOpenValue && this.openValue) {
      this.open()
    }
  }

  open() {
    const scrollBarWidth = window.innerWidth - document.body.offsetWidth

    document.body.style.paddingRight = `${scrollBarWidth}px`
    document.body.classList.add("overflow-hidden")
    this.element.classList.remove("hidden")
  }

  close() {
    document.body.style.paddingRight = "0px"
    document.body.classList.remove("overflow-hidden")
    this.element.classList.add("hidden")
  }

  openModal(e?: StimulusEvent<TriggerParams>) {
    e?.preventDefault()

    if (!e?.params?.modal) {
      return console.error("No modal target specified")
    }

    const modal = document.querySelector<HTMLElement>(`.twui-modal[data-modal='${e?.params?.modal}']`)

    if (modal) {
      const modalController = this.application.getControllerForElementAndIdentifier(
        modal,
        "twui-modal"
      ) as TwuiModalController | null
      modalController?.open()
    }
  }
}
