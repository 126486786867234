import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "optionsWrapper", "option"]

  declare inputTarget: HTMLInputElement
  declare hasInputTarget: boolean

  declare optionsWrapperTarget: HTMLDivElement

  declare optionTargets: HTMLDivElement[]

  connect() {
    document.addEventListener("click", this.clickHandler.bind(this))
  }

  clickHandler(e: PointerEvent) {
    if (!this.element.contains(e.target as HTMLElement)) {
      this.optionsWrapperTarget.classList.add("hidden")
    }
  }

  clear() {
    this.inputTarget.value = ""
    this.optionsWrapperTarget.classList.add("hidden")
  }

  handleTyping(e?: InputEvent) {
    const value = this.inputTarget.value

    if ((e?.inputType == "deleteContentBackward" && value == "") || this.noMatches()) {
      this.optionsWrapperTarget.classList.add("hidden")
    } else {
      this.optionsWrapperTarget.classList.remove("hidden")
    }

    for (const option of this.optionTargets) {
      if (option.dataset.value?.includes(value)) {
        option.classList.remove("hidden")
      } else {
        option.classList.add("hidden")
      }
    }
  }

  showAll() {
    this.optionsWrapperTarget.classList.remove("hidden")

    if (this.inputTarget.value == "") {
      for (const option of this.optionTargets) {
        option.classList.remove("hidden")
      }
    }
  }

  noMatches() {
    return this.optionTargets.filter(option => option.dataset.value?.includes(this.inputTarget.value)).length == 0
  }

  disableOptions(e: CustomEvent) {
    for (const id of e.detail.ids) {
      const option = this.optionTargets.find(option => option.dataset.id == id)

      if (option) {
        option.classList.add("disabled")
      }
    }
  }

  enableOptions(e: CustomEvent) {
    for (const id of e.detail.ids) {
      const option = this.optionTargets.find(option => option.dataset.id == id)

      if (option) {
        option.classList.remove("disabled")
      }
    }
  }
}
