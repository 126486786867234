export const offset = (el?: Element | null) => {
  if (!el) {
    return null
  }

  const rect = el.getBoundingClientRect()

  const offset = {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
  }

  return offset
}

export default {
  offset,
}
