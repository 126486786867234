import fetch from "@/common/fetch"

const apiFetch = (url: string, opts = {}) => {
  const request = new Request(url, opts)

  if (request.method !== "GET") {
    request.headers.set("Accept", "application/json")
    request.headers.set("Content-Type", "application/json")
  }

  return fetch(request).then(response => {
    if (response.ok) {
      return response.json()
    } else if (response.status < 500) {
      return response.json().then(data => {
        if (data.csrf_error && data.csrf_error === true) {
          alert("Your session has expired. Please reload the page and try again.")
          return
        }
        throw data.error || data
      })
    } else {
      throw response.statusText
    }
  })
}

export default {
  get: (url: string) => {
    return apiFetch(url)
  },

  post: (url: string, data: any) => {
    return apiFetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    })
  },

  patch: (url: string, data: any) => {
    return apiFetch(url, {
      method: "PATCH",
      body: JSON.stringify(data),
    })
  },

  put: (url: string, data: any) => {
    return apiFetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
    })
  },

  delete: (url: string) => {
    return apiFetch(url, {
      method: "DELETE",
    })
  },
}
