import { Controller } from "@hotwired/stimulus"

interface ItemParams {
  label: string
  icon: string
}

export default class extends Controller {
  static targets = ["list", "icon", "label", "option"]

  declare listTarget: HTMLUListElement
  declare hasListTarget: boolean

  declare iconTarget: HTMLElement
  declare hasIconTarget: boolean

  declare labelTarget: HTMLElement
  declare hasLabelTarget: boolean

  declare optionTargets: HTMLElement[]

  get open() {
    return this.hasListTarget && !this.listTarget.classList.contains("hidden")
  }

  toggle() {
    if (!this.hasListTarget) {
      return
    }

    if (this.open) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    if (!this.hasListTarget) {
      return
    }

    this.listTarget.classList.remove("hidden", "opacity-0", "transition")
    this.listTarget.classList.add("opacity-100")
    this.listTarget.focus()
  }

  hide() {
    if (!this.hasListTarget) {
      return
    }

    this.listTarget.classList.add("transition", "opacity-0")
    setTimeout(() => this.listTarget.classList.add("hidden"), 100)
  }

  pageClicked(e: MouseEvent) {
    if (this.open && e.target instanceof Element && !this.element.contains(e.target)) {
      this.hide()
    }
  }

  itemSelect(e: StimulusEvent<ItemParams>) {
    for (const option of this.optionTargets) {
      option.classList.remove("active")

      if (option === e.currentTarget) {
        option.classList.add("active")
      }
    }

    if (this.hasLabelTarget && e.params?.label) {
      this.labelTarget.innerHTML = e.params.label
    }

    if (this.hasIconTarget && e.params?.icon) {
      this.iconTarget.innerHTML = e.params.icon
    }

    this.hide()
  }
}
