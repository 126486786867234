interface WindowOptions {
  [index: string]: boolean | number | string | undefined
  width?: number
  height?: number
  left?: number
  top?: number
  location?: boolean
  toolbar?: boolean
  status?: boolean
  directories?: boolean
  menubar?: boolean
  scrollbars?: boolean
  resizable?: boolean
  centerscreen?: boolean
  chrome?: boolean
}

const defaults: WindowOptions = {
  width: 550,
  height: 400,
  location: false,
  toolbar: false,
  status: false,
  directories: false,
  menubar: false,
  scrollbars: true,
  resizable: false,
  centerscreen: true,
  chrome: true,
}

export default function windowOpen(url: string, options: WindowOptions, context?: string): void {
  const config = {
    ...defaults,
    ...options,
  }

  if (config.centerscreen) {
    config.left =
      config.left || window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - (config.width || 0) / 2
    config.top =
      config.top || window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - (config.height || 0) / 2
  }

  window.open(
    url,
    context,
    Object.keys(config)
      .map(key => {
        let value = config[key]

        if (value === true) {
          value = "yes"
        }

        if (value === false) {
          value = "no"
        }

        return `${key}=${value}`
      })
      .join(", ")
  )
}
