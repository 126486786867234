import { Controller } from "@hotwired/stimulus"
import { template } from "lodash-es"

interface RemoveParams {
  id: number
}

interface AddParams {
  id: string
  label: string
  href?: string
  values: string
}

export default class extends Controller {
  static targets = [
    "menu",
    "clearBtn",
    "input",
    "inputMeasure",
    "item",
    "emptyItem",
    "newItem",
    "selectedItem",
    "selectedTemplate",
    "newTemplate",
    "insertPoint",
  ]

  declare menuTarget: HTMLElement
  declare hasMenuTarget: boolean

  declare clearBtnTarget: HTMLElement
  declare inputTarget: HTMLInputElement
  declare inputMeasureTarget: HTMLElement

  declare itemTargets: HTMLElement[]
  declare selectedItemTargets: HTMLElement[]
  declare emptyItemTarget: HTMLElement

  declare newItemTarget: HTMLElement
  declare hasNewItemTarget: boolean

  declare selectedTemplateTarget: HTMLElement
  declare newTemplateTarget: HTMLElement
  declare insertPointTarget: HTMLElement

  selectedTemplate?: (data?: Partial<AddParams>) => string
  newTemplate?: (data?: Partial<AddParams>) => string

  get open() {
    return this.hasMenuTarget && !this.menuTarget.classList.contains("hidden")
  }

  get possibleItems() {
    const items = [...this.itemTargets]

    if (this.hasNewItemTarget) {
      items.push(this.newItemTarget)
    }

    return items.filter(i => !i.classList.contains("hidden"))
  }

  selectedTemplateTargetConnected() {
    this.selectedTemplate = template(this.selectedTemplateTarget.innerHTML)
  }

  newTemplateTargetConnected() {
    this.newTemplate = template(this.newTemplateTarget.innerHTML)
  }

  focus() {
    this.element.classList.add("focus")
    this.inputTarget.focus()
  }

  blur() {
    this.element.classList.remove("focus")
  }

  toggle() {
    if (!this.hasMenuTarget) {
      return
    }

    if (this.open) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    if (!this.hasMenuTarget) {
      return
    }

    this.filter()

    if (this.open) return

    this.menuTarget.classList.remove("hidden", "opacity-0", "transition")
    this.menuTarget.classList.add("opacity-100")
    this.menuTarget.focus()
  }

  hide() {
    if (!this.hasMenuTarget) {
      return
    }

    this.inputTarget.value = ""
    this.menuTarget.classList.add("transition", "opacity-0")
    setTimeout(() => this.menuTarget.classList.add("hidden"), 100)
  }

  pageClicked(e: MouseEvent) {
    if (this.open && e.target instanceof Element && !this.element.contains(e.target)) {
      this.hide()
    }
  }

  filter() {
    const value = this.inputTarget.value
    const lowerValue = value.toLowerCase()
    let matched = 0
    let exactMatch = false

    const selectedIds = this.selectedItemTargets.map(t => t.dataset.id)

    for (const item of this.itemTargets) {
      if (selectedIds.includes(item.dataset.id)) {
        item.classList.add("hidden")
        continue
      }

      if (matched < 5) {
        const values = item.dataset.values?.split("|") || []
        const matching = values.find(v => v.toLowerCase().includes(lowerValue))

        if (matching?.toLowerCase() === lowerValue) {
          exactMatch = true
        }

        if (matching) {
          matched += 1
          item.classList.remove("hidden")
        } else {
          item.classList.add("hidden")
        }
      } else {
        item.classList.add("hidden")
      }
    }

    if (this.hasNewItemTarget) {
      const values = this.itemTargets
        .concat(this.selectedItemTargets)
        .map(t => t.dataset.values?.split("|") || [])
        .flat()
        .map(v => v.toLowerCase())

      if (exactMatch || values.includes(lowerValue)) {
        this.newItemTarget.classList.add("hidden")
      } else {
        const newLabel = this.newItemTarget.querySelector("span")
        if (newLabel) newLabel.innerHTML = `New item - ${value}`
        this.newItemTarget.classList.remove("hidden")
      }
    }

    if (matched > 0) {
      this.emptyItemTarget.classList.add("hidden")
    } else {
      this.emptyItemTarget.classList.remove("hidden")
    }

    this.updateActive()
  }

  updateActive() {
    const items = this.possibleItems

    if (items.length > 0) {
      items[0].classList.add("active")

      for (const active of items.slice(1).filter(i => i.classList.contains("active"))) {
        active.classList.remove("active")
      }
    }
  }

  inputTargetConnected() {
    this.updateInputWidth()
  }

  inputChange() {
    this.updateInputWidth()

    if (this.inputTarget.value.length > 0) {
      this.show()
    } else {
      this.hide()
    }
  }

  updateInputWidth() {
    this.inputMeasureTarget.innerHTML = this.inputTarget.value
    this.inputMeasureTarget.classList.remove("hidden")
    const width = this.inputMeasureTarget.clientWidth
    this.inputMeasureTarget.classList.add("hidden")

    this.inputTarget.style.width = `${Math.max(25, width)}px`
  }

  inputSubmit(e?: KeyboardEvent) {
    if (e?.key === "Escape") {
      e?.preventDefault()

      this.inputTarget.value = ""
      this.hide()
    }

    if (["ArrowDown", "ArrowUp", "Enter"].includes(e?.key || "")) {
      e?.preventDefault()

      const items = this.possibleItems
      const active = items.find(i => i.classList.contains("active"))

      if (active) {
        const activeIndex = items.indexOf(active)

        if (e?.key === "Enter") {
          active?.click()
        } else if (e?.key === "ArrowDown") {
          const next = activeIndex === items.length - 1 ? 0 : activeIndex + 1

          active.classList.remove("active")
          items[next].classList.add("active")
        } else if (e?.key === "ArrowUp") {
          const next = activeIndex === 0 ? items.length - 1 : activeIndex - 1

          active.classList.remove("active")
          items[next].classList.add("active")
        }
      }
    }
  }

  addItem(e?: StimulusEvent<AddParams>) {
    e?.preventDefault()

    if (this.selectedTemplate && e?.params) {
      const html = this.selectedTemplate(e?.params)
      this.insertPointTarget.insertAdjacentHTML("beforebegin", html)
    }

    this.inputTarget.value = ""
    this.hide()
  }

  addNew(e?: Event) {
    e?.preventDefault()
    e?.stopPropagation()

    if (this.newTemplate) {
      const value = this.inputTarget.value
      const html = this.newTemplate({ id: `new_${value}`, label: value, values: value })
      this.insertPointTarget.insertAdjacentHTML("beforebegin", html)
    }

    this.inputTarget.value = ""
    this.hide()
  }

  removeItem(e?: StimulusEvent<RemoveParams>) {
    e?.preventDefault()
    e?.stopPropagation()

    this.selectedItemTargets.find(t => t.dataset.id?.toString() === e?.params?.id?.toString())?.remove()

    if (this.open) {
      this.focus()
      this.filter()
    }
  }
}
