import { Controller } from "@hotwired/stimulus"
import { type ChainedCommands, Editor, mergeAttributes, Node, type Extensions, generateJSON } from "@tiptap/core"
import StarterKit from "@tiptap/starter-kit"
import Placeholder from "@tiptap/extension-placeholder"
import TextAlign from "@tiptap/extension-text-align"
import Underline from "@tiptap/extension-underline"
import Image from "@tiptap/extension-image"
import type { Level } from "@tiptap/extension-heading"
import Paragraph from "@tiptap/extension-paragraph"
import Link from "@tiptap/extension-link"
import Subscript from "@tiptap/extension-subscript"
import Superscript from "@tiptap/extension-superscript"
import Highlight from "@tiptap/extension-highlight"
import Typography from "@tiptap/extension-typography"
import ListItem from "@tiptap/extension-list-item"
import { v4 } from "uuid"

/**
 * https://tiptap.dev/introduction
 */

// Make div the default block element
const Div = Node.create({
  name: "div",
  priority: 1001,
  group: "block",
  content: "inline*",

  parseHTML() {
    return [{ tag: "div" }]
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
})

const isDivTag = (node: ChildNode | null): node is HTMLDivElement => {
  return node?.nodeName === "DIV"
}

type ParagraphStyle =
  | "paragraph"
  | "paragraph mb-0"
  | "paragraph no-max-width"
  | "paragraph-sm"
  | "paragraph-xs"
  | "paragraph-2xs"
const paragraphStyles: ParagraphStyle[] = [
  "paragraph",
  "paragraph mb-0",
  "paragraph no-max-width",
  "paragraph-sm",
  "paragraph-xs",
  "paragraph-2xs",
]

interface StyledParagraphOptions {
  styles: ParagraphStyle[]
  HTMLAttributes: Record<string, any>
}

// Our custom Paragraph node with a predefined list of possible tw classes
const StyledParagraph = Paragraph.extend<StyledParagraphOptions>({
  addOptions() {
    return {
      styles: paragraphStyles,
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      style: {
        default: "paragraph",
        rendered: false,
        parseHTML: element => element.getAttribute("class"),
      },
    }
  },

  renderHTML({ node, HTMLAttributes }) {
    const hasStyle = this.options.styles.includes(node.attrs.style)
    const style = hasStyle ? node.attrs.style : this.options.styles[0]

    return [`p`, mergeAttributes({ class: style }, this.options.HTMLAttributes, HTMLAttributes), 0]
  },
})

// allow using lists with div nodes
const ExtendedListItem = ListItem.extend({
  content: "(div|paragraph) block*",
})

interface AlignParams {
  align: string
}

interface NodeFormatParams {
  format: string
}

interface RootElement extends Element {
  tiptapEditor?: Editor
}

export default class extends Controller {
  static values = {
    value: Object,
    html: String,
  }

  declare valueValue: object
  declare hasValueValue: boolean

  declare htmlValue: string
  declare hasHtmlValue: boolean

  static targets = [
    "editor",
    "htmlInput",
    "jsonInput",
    "node",
    "nodeMenuWrapper",
    "nodeMenu",
    "nodeOption",
    "btnBold",
    "btnItalic",
    "btnUnderline",
    "btnsAlign",
    "btnAlignLeft",
    "btnAlignCenter",
    "btnAlignRight",
    "btnsLink",
    "btnLink",
    "btnUnlink",
    "btnBulletList",
    "btnOrderedList",
    "btnReset",
    "btnsUndo",
    "btnUndo",
    "btnRedo",
    "btnOverflow",
    "menu",
    "overflowMenu",
    "overflowMenuDropdown",
  ]

  declare editorTarget: HTMLElement
  declare hasEditorTarget: boolean

  declare htmlInputTarget: HTMLInputElement
  declare hasHtmlInputTarget: boolean

  declare jsonInputTarget: HTMLInputElement
  declare hasJsonInputTarget: boolean

  declare nodeTarget: HTMLElement

  declare nodeMenuWrapperTarget: HTMLElement

  declare nodeMenuTarget: HTMLElement
  declare hasNodeMenuTarget: boolean

  declare nodeOptionTargets: HTMLElement[]

  declare btnBoldTargets: HTMLButtonElement[]
  declare btnItalicTargets: HTMLButtonElement[]
  declare btnUnderlineTargets: HTMLButtonElement[]
  declare btnAlignLeftTargets: HTMLButtonElement[]
  declare btnAlignCenterTargets: HTMLButtonElement[]
  declare btnAlignRightTargets: HTMLButtonElement[]
  declare btnLinkTargets: HTMLButtonElement[]
  declare btnUnlinkTargets: HTMLButtonElement[]
  declare btnBulletListTargets: HTMLButtonElement[]
  declare btnOrderedListTargets: HTMLButtonElement[]
  declare btnResetTargets: HTMLButtonElement[]
  declare btnUndoTargets: HTMLButtonElement[]
  declare btnRedoTargets: HTMLButtonElement[]

  declare btnsAlignTargets: HTMLElement[]
  declare btnsLinkTargets: HTMLElement[]
  declare btnsUndoTargets: HTMLElement[]

  declare btnOverflowTarget: HTMLButtonElement

  declare menuTarget: HTMLElement

  declare overflowMenuTarget: HTMLElement

  declare overflowMenuDropdownTarget: HTMLElement
  declare hasOverflowMenuDropdownTarget: boolean

  private editor?: Editor
  private resizeObserver?: ResizeObserver

  private overflowCandidates: Array<HTMLElement[]> = []
  private overflowCandidateWidths: number[] = []

  get nodeMenuOpen() {
    return this.hasNodeMenuTarget && !this.nodeMenuTarget.classList.contains("hidden")
  }

  get overflowMenuOpen() {
    return this.hasOverflowMenuDropdownTarget && !this.overflowMenuDropdownTarget.classList.contains("hidden")
  }

  get rootElement() {
    return this.element as RootElement
  }

  get menuShrunk() {
    return this.overflowCandidates.some(t => t[0].classList.contains("hidden"))
  }

  get menuFreeWidth() {
    return (this.menuTarget.parentElement?.clientWidth || 0) - this.nodeMenuWrapperTarget.clientWidth - 4
  }

  connect() {
    this.element.id = `${this.identifier}-${v4()}`

    if (this.hasEditorTarget && this.hasJsonInputTarget && !this.rootElement.tiptapEditor) {
      const extensions: Extensions = [
        StarterKit.configure({ paragraph: false, listItem: false }),
        ExtendedListItem,
        Div,
        StyledParagraph,
        Placeholder,
        Underline,
        Link.configure({ openOnClick: false, HTMLAttributes: { target: "_self", rel: "noopener" } }),
        Subscript,
        Superscript,
        Highlight,
        Image.configure({ inline: true }),
        TextAlign.configure({ types: ["heading", "paragraph", "div"] }),
        Typography.configure({
          openDoubleQuote: false,
          closeDoubleQuote: false,
          openSingleQuote: false,
          closeSingleQuote: false,
        }),
      ]

      const content = this.hasValueValue ? this.valueValue : generateJSON(this.htmlValue, extensions)

      this.editor = new Editor({
        element: this.editorTarget,
        extensions,
        content,
        onSelectionUpdate: () => {
          this.updateMenu()
        },
        onUpdate: () => {
          this.saveOutput()
          this.updateMenu()
        },
        onCreate: () => {
          this.saveOutput()
          this.updateMenu()
        },
        onFocus: () => {
          this.element.classList.add("focus")
          this.hideNodeMenu()
        },
      })

      this.overflowCandidates = [
        this.btnBoldTargets,
        this.btnItalicTargets,
        this.btnUnderlineTargets,
        this.btnsAlignTargets,
        this.btnsLinkTargets,
        this.btnBulletListTargets,
        this.btnOrderedListTargets,
        this.btnResetTargets,
        this.btnsUndoTargets,
      ]

      for (const candidate of this.overflowCandidates) {
        this.overflowCandidateWidths.push(candidate[0].clientWidth)
      }

      this.resizeObserver = new ResizeObserver(() => {
        if (this.menuFreeWidth < this.menuTarget.clientWidth) {
          this.shrinkMenu()
        } else if (this.menuShrunk && this.menuFreeWidth - this.menuTarget.clientWidth > 40) {
          this.expandMenu()
        }
      })

      this.resizeObserver.observe(this.rootElement)
      this.resizeObserver.observe(this.menuTarget)

      this.rootElement.tiptapEditor = this.editor
    }
  }

  disconnect() {
    this.resizeObserver?.disconnect()
    this.rootElement.tiptapEditor = undefined
    this.editor?.destroy()
  }

  saveOutput() {
    if (!this.editor) {
      return
    }

    const html = this.editor.getHTML()
    const container = document.createElement("div")
    container.innerHTML = html

    // tiptap has some.. philosophical ideas about empty nodes and HTML output
    // https://github.com/ueberdosis/tiptap/issues/412
    // https://github.com/ueberdosis/tiptap/issues/1500
    for (const node of Array.from(container.childNodes)) {
      if (isDivTag(node)) {
        if (node.innerHTML !== "") continue

        node.innerHTML = "<br>"
      }
    }

    this.htmlInputTarget.value = container.innerHTML
    this.jsonInputTarget.value = JSON.stringify(this.editor.getJSON())
  }

  /** Update menu buttons state based on selection */
  updateMenu() {
    const buttons: Array<[string | Record<string, unknown>, HTMLElement[]]> = [
      ["bold", this.btnBoldTargets],
      ["italic", this.btnItalicTargets],
      ["underline", this.btnUnderlineTargets],
      [{ textAlign: "left" }, this.btnAlignLeftTargets],
      [{ textAlign: "center" }, this.btnAlignCenterTargets],
      [{ textAlign: "right" }, this.btnAlignRightTargets],
      ["link", this.btnLinkTargets],
      ["bulletList", this.btnBulletListTargets],
      ["orderedList", this.btnOrderedListTargets],
    ]

    for (const [name, btns] of buttons) {
      const active = this.editor?.isActive(name)

      for (const btn of btns) {
        active ? btn.classList.add("active") : btn.classList.remove("active")
      }
    }

    this.btnBulletListTargets.forEach(b => (b.disabled = !this.editor?.can().toggleBulletList()))
    this.btnOrderedListTargets.forEach(b => (b.disabled = !this.editor?.can().toggleOrderedList()))
    this.btnUnlinkTargets.forEach(b => (b.disabled = !this.editor?.isActive("link")))
    this.btnUndoTargets.forEach(b => (b.disabled = !this.editor?.can().undo()))
    this.btnRedoTargets.forEach(b => (b.disabled = !this.editor?.can().redo()))

    this.updateNodeMenu()
  }

  /** Update active node type in node menu */
  updateNodeMenu() {
    if (this.editor?.isActive("heading")) {
      const level = this.editor.getAttributes("heading")["level"]

      if (level) {
        this.nodeTarget.innerText = `Heading ${level}`
      }
    } else if (this.editor?.isActive("paragraph")) {
      const style: ParagraphStyle = this.editor.getAttributes("paragraph")["style"]

      switch (style) {
        case "paragraph mb-0":
          this.nodeTarget.innerText = "P w/o margin"
          break
        case "paragraph no-max-width":
          this.nodeTarget.innerText = "P 100% width"
          break
        case "paragraph-sm":
          this.nodeTarget.innerText = "P small"
          break
        case "paragraph-xs":
          this.nodeTarget.innerText = "P xs"
          break
        case "paragraph-2xs":
          this.nodeTarget.innerText = "P 2xs"
          break
        default:
          this.nodeTarget.innerText = "Paragraph"
          break
      }
    } else if (this.editor?.isActive("div")) {
      this.nodeTarget.innerText = "Text"
    }

    if (this.nodeMenuOpen && this.editor) {
      for (const option of this.nodeOptionTargets) {
        option.classList.remove("active")
      }

      const format: string | number | undefined =
        this.editor.getAttributes("heading")["level"] || this.editor.getAttributes("paragraph")["style"]
      const target = this.nodeOptionTargets.find(e => e.dataset["tiptapEditorFormatParam"] === format?.toString())
      target?.classList.add("active")
    }
  }

  shrinkMenu() {
    for (const candidate of [...this.overflowCandidates].reverse()) {
      const [main, overflow] = candidate

      if (main.classList.contains("hidden")) continue

      main.classList.add("hidden")
      overflow.classList.remove("hidden")

      if (isDivTag(main.previousSibling) && main.previousSibling.classList.contains("divider")) {
        main.previousSibling.classList.add("hidden")
      }

      if (isDivTag(overflow.nextSibling) && overflow.nextSibling.classList.contains("divider")) {
        overflow.nextSibling.classList.remove("hidden")
      }

      if (this.menuFreeWidth >= this.menuTarget.clientWidth) {
        break
      }
    }

    this.updateOverflowMenu()
  }

  expandMenu() {
    for (let index = 0; index < this.overflowCandidates.length; index++) {
      const candidate = this.overflowCandidates[index]
      const [main, overflow] = candidate

      if (!main.classList.contains("hidden")) continue

      const candidateWidth = this.overflowCandidateWidths[index] || 0

      if (this.menuFreeWidth <= this.menuTarget.clientWidth + candidateWidth + 8) {
        break
      }

      main.classList.remove("hidden")
      overflow.classList.add("hidden")

      if (isDivTag(main.previousSibling) && main.previousSibling.classList.contains("divider")) {
        main.previousSibling.classList.remove("hidden")
      }

      if (isDivTag(overflow.nextSibling) && overflow.nextSibling.classList.contains("divider")) {
        overflow.nextSibling.classList.add("hidden")
      }
    }

    this.updateOverflowMenu()
  }

  updateOverflowMenu() {
    if (this.menuShrunk) {
      this.overflowMenuTarget.classList.remove("hidden")
    } else {
      this.overflowMenuTarget.classList.add("hidden")
    }
  }

  cmdBold(e: Event) {
    e.preventDefault()
    this.commandWithSelection()?.toggleBold().run()
  }

  cmdItalic(e: Event) {
    e.preventDefault()
    this.commandWithSelection()?.toggleItalic().run()
  }

  cmdUnderline(e: Event) {
    e.preventDefault()
    this.commandWithSelection()?.toggleUnderline().run()
  }

  cmdAlign(e: StimulusEvent<AlignParams>) {
    e.preventDefault()

    if (e.params?.align) {
      this.editor?.chain().focus().setTextAlign(e.params?.align).run()
    }
  }

  cmdLink(e: Event) {
    e.preventDefault()

    const current = this.editor?.getAttributes("link").href
    const link = prompt("URL", current)

    if (link) {
      this.commandWithSelection(c => c?.extendMarkRange("link"))
        ?.setLink({ href: link })
        .run()
    }
  }

  cmdUnlink(e: Event) {
    e.preventDefault()
    this.editor?.chain().focus().unsetLink().run()
  }

  cmdBulletList(e: Event) {
    e.preventDefault()
    this.editor?.chain().focus().toggleBulletList().run()
  }

  cmdOrderedList(e: Event) {
    e.preventDefault()
    this.editor?.chain().focus().toggleOrderedList().run()
  }

  cmdReset(e: Event) {
    e.preventDefault()
    this.editor?.chain().focus().clearNodes().unsetAllMarks().run()
  }

  cmdUndo(e: Event) {
    e.preventDefault()
    this.editor?.chain().focus().undo().run()
  }

  cmdRedo(e: Event) {
    e.preventDefault()
    this.editor?.chain().focus().redo().run()
  }

  cmdHeading(e: StimulusEvent<NodeFormatParams>) {
    e.preventDefault()

    if (e.params?.format) {
      const level = parseInt(e.params.format) as Level
      this.editor?.chain().focus().toggleHeading({ level }).run()
    }

    this.hideNodeMenu()
  }

  cmdParagraph(e: StimulusEvent<NodeFormatParams>) {
    e.preventDefault()

    if (e.params?.format) {
      this.editor
        ?.chain()
        .focus()
        .command(c => c.commands.setNode("paragraph", { style: e.params?.format }))
        .run()
    }

    this.hideNodeMenu()
  }

  cmdRoot(e: Event) {
    e.preventDefault()

    this.editor
      ?.chain()
      .focus()
      .command(c => c.commands.setNode("div"))
      .run()
  }

  cmdOverflow(e: Event) {
    e.preventDefault()

    if (this.overflowMenuOpen) {
      this.hideOverflowMenu()
    } else {
      this.showOverflowMenu()
    }
  }

  showOverflowMenu() {
    if (!this.hasOverflowMenuDropdownTarget) {
      return
    }

    this.overflowMenuDropdownTarget.classList.remove("hidden")
    this.btnOverflowTarget.classList.add("active")
    this.editor?.commands.focus()
  }

  hideOverflowMenu() {
    if (!this.hasOverflowMenuDropdownTarget) {
      return
    }

    this.btnOverflowTarget.classList.remove("active")
    this.overflowMenuDropdownTarget.classList.add("hidden")
  }

  toggleNodeMenu() {
    if (!this.hasNodeMenuTarget) {
      return
    }

    if (this.nodeMenuOpen) {
      this.hideNodeMenu()
    } else {
      this.showNodeMenu()
    }
  }

  showNodeMenu() {
    if (!this.hasNodeMenuTarget) {
      return
    }

    this.element.classList.add("nodemenu-open")
    this.nodeMenuTarget.classList.remove("hidden")
    this.updateNodeMenu()
  }

  hideNodeMenu() {
    if (!this.hasNodeMenuTarget) {
      return
    }

    this.element.classList.remove("nodemenu-open")
    this.nodeMenuTarget.classList.add("hidden")
  }

  pageClicked(e: MouseEvent) {
    if (this.nodeMenuOpen && e.target instanceof Element && !e.target.closest(`#${this.element.id} .node-menu`)) {
      this.hideNodeMenu()
    }

    if (this.overflowMenuOpen && e.target instanceof Element && !e.target.closest(`#${this.element.id}`)) {
      this.hideOverflowMenu()
    }

    // we can't do this via tiptap onBlur because the menu bar is outside the editor so clicking menu buttons makes the
    // editor lose focus for a moment making the focus styles flicker
    if (e.target instanceof Element && !e.target.closest(`#${this.element.id}`)) {
      this.element.classList.remove("focus")
    }
  }

  /** Start a command chain that first selects the full word if the cursor is inside one */
  private commandWithSelection(before?: (chain: ChainedCommands | undefined) => ChainedCommands | undefined) {
    const selection = this.wordSelection()
    let chain = this.editor?.chain().focus()

    if (before) {
      chain = before(chain)
    }

    if (selection) {
      chain = chain?.command(c => c.commands.setTextSelection({ from: selection.from, to: selection.to }))
    }

    return chain
  }

  /**
   * If selection is empty and cursor is inside a word returns the start and end positions of the word for selection
   */
  private wordSelection() {
    if (!this.editor?.state.selection.empty) {
      return null
    }

    for (const range of this.editor.state.selection.ranges || []) {
      const { $from, $to } = range
      let from = $from.pos
      let to = $to.pos
      const start = $from.nodeBefore
      const end = $to.nodeAfter

      if (start?.isText && end?.isText && start.text && end.text) {
        const spaceStart = start.text.match(/\S+/g)?.at(-1)?.length || 0
        const spaceEnd = end.text.match(/\S+/g)?.at(0)?.length || 0

        if (from - spaceStart < to) {
          from -= spaceStart
          to += spaceEnd
        }

        return { from, to }
      }
    }

    return null
  }
}
