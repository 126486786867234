export function getAdminSessionKey(name = "admin-shared-session-key") {
  if (import.meta.env.SSR) {
    return null
  }

  const element = document.head.querySelector(`meta[name='${name}']`)

  if (element) {
    return element.getAttribute("content")
  }

  return null
}

export function getAdminSessionParam(name = "admin-shared-session-param") {
  if (import.meta.env.SSR) {
    return null
  }

  const element = document.head.querySelector(`meta[name='${name}']`)

  if (element) {
    return element.getAttribute("content")
  }

  return null
}
