import validations from "./util/validations"
import windowOpen from "./util/window"
import misc from "./util/misc"
import DOM from "./util/dom"
export * from "./util/misc"

const Util = {
  ...misc,
  validations,
  windowOpen,
  misc,
  DOM,
}

export default Util
