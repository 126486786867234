import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["label"]

  declare labelTarget: HTMLLabelElement
  declare hasLabelTarget: boolean

  connect() {
    if (this.hasLabelTarget) {
      const radio = this.labelTarget.querySelector<HTMLInputElement>("input[type=radio]")

      if (radio) {
        radio.addEventListener("change", () => {
          this.labelTarget.parentElement?.querySelectorAll("label").forEach(label => label.classList.remove("active"))
          this.labelTarget.classList.add("active")
        })
      }
    }
  }
}
