import React from "react"
import env from "@/common/env"
import { type ElementRects, type Elements } from "@floating-ui/react"

declare global {
  interface Window {
    gtag: any
    Trustpilot: any
  }
}

export const isBlank = (text?: string | null) => !text || text.length === 0 || !text.trim()

export const objEmpty = (obj: any) => !obj || Object.keys(obj).length === 0

export const escapeHtml = (html: string) => {
  const text = document.createTextNode(html)
  const p = document.createElement("p")
  p.appendChild(text)
  return p.innerHTML
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const keys = <O extends object>(obj: O): Array<keyof O> => {
  return Object.keys(obj) as Array<keyof O>
}

export const snakeCase = (s: string) => s.replace(/\.?([A-Z]+)/g, (x, y) => "_" + y.toLowerCase()).replace(/^_/, "")

export const repeatElement = (times: number, component: React.ComponentType) =>
  Array.from(Array(times)).map((_, i) => React.createElement(component, { key: i }))

const DELIM = "|"

export const stringifyObj = (obj: any): string => {
  if (Array.isArray(obj)) {
    const stringifiedArr = []
    for (let i = 0; i < obj.length; i++) {
      stringifiedArr[i] = stringifyObj(obj[i])
    }

    return JSON.stringify(stringifiedArr)
  } else if (typeof obj === "object" && obj !== null) {
    const acc = []
    const sortedKeys = Object.keys(obj).sort()

    for (let i = 0; i < sortedKeys.length; i++) {
      const k = sortedKeys[i]

      if (typeof obj[k] === "symbol") {
        continue
      }

      acc[i] = `${k}:${stringifyObj(obj[k])}`
    }

    return acc.join(DELIM)
  }

  return obj
}

// Use with Array.filter for example: filters Array<T | undefined> down to T[]
export const isPresent = <T>(t: T | undefined | null | void): t is T => {
  return t !== undefined && t !== null
}

export const waitUntil = (predicate: () => boolean, checkInterval = 50, timeout = 5000) => {
  return new Promise<void>(resolve => {
    const waitTimeout = new Promise((_, reject) => {
      const wait = setTimeout(() => {
        clearTimeout(wait)
        reject()
      }, timeout)
    })

    const checkPredicate = new Promise<void>(resolveCheck => {
      const check = (resolveCheck: () => void) => {
        if (predicate()) {
          return resolveCheck()
        } else {
          window.setTimeout(() => check(resolveCheck), checkInterval)
        }
      }

      check(resolveCheck)
    })

    Promise.race([waitTimeout, checkPredicate]).then(
      () => resolve(),
      () => {
        resolve()
      }
    )
  })
}

export const clamp = (num: number, min: number, max: number) =>
  Math.max(Math.min(num, Math.max(min, max)), Math.min(min, max))

const setTimeoutWrapper = (c: () => void) => window.setTimeout(c, 0)
export const raf =
  (typeof window !== "undefined" && (window.requestAnimationFrame || window.setImmediate)) || setTimeoutWrapper

export const cssTime = (css: string) => {
  const num = parseFloat(css)

  if (css.endsWith("ms")) {
    return num
  }

  return num * 1000
}

export const cssPx = (css: string) => {
  return parseInt(css, 0)
}

export const isTablet = () => {
  return window.innerWidth < 1024
}

export const isMobile = () => {
  return window.innerWidth <= 767
}

export const formatMonth = (date?: string) => {
  if (!date) {
    return ""
  }

  const d = new Date(date)
  let month = "" + (d.getMonth() + 1)
  const year = d.getFullYear()

  if (month.length < 2) {
    month = "0" + month
  }

  return [year, month].join("-")
}

export const dig = (object: any, search: string) => {
  search = search.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties, a.[0] -> a.0
  search = search.replace(/^\./, "") // strip a leading dot

  const parts = search.split(".")

  for (let i = 0, n = parts.length; i < n; ++i) {
    const key = parts[i]
    if (key in object) {
      object = object[key]
    } else {
      return
    }
  }

  return object
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes == 0) return "0 Bytes"

  const k = 1024
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i]
}

export const capitalize = (word: string) => word[0].toUpperCase() + word.substr(1)

export const round = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100

export const toggleScroll = (lock: boolean) => {
  const bodyClasses = document.body.classList
  const scrollPos = window.scrollY
  const scrollY = document.body.style.top

  if (lock) {
    bodyClasses.add("overflow-hidden")
    bodyClasses.add("w-full")
    bodyClasses.add("fixed")
    document.documentElement.classList.add("h-screen")
    document.body.style.top = `-${scrollPos}px`
  } else {
    document.body.classList.remove("overflow-hidden")
    document.body.classList.remove("w-full")
    document.body.classList.remove("fixed")
    document.documentElement.classList.remove("h-screen")
    document.body.style.removeProperty("top")
    window.scrollTo(0, parseInt(scrollY || "0") * -1)
  }
}

export const pushToDataLayer = (obj: any) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(obj)
  }
}

export const pushConsentToDataLayer = (functional: boolean, analytics: boolean, marketing: boolean) => {
  window.gtag("consent", "update", {
    functionality_storage: functional ? "granted" : "denied",
    analytics_storage: analytics ? "granted" : "denied",
    ad_storage: marketing ? "granted" : "denied",
    ad_user_data: marketing ? "granted" : "denied",
    ad_personalization: marketing ? "granted" : "denied",
  })
}

export const basketAddToGA4 = (
  itemId: string | null,
  price: number,
  count?: number,
  discountPercent?: number,
  signTypeKey?: string | null,
  size?: string,
  hangType?: string | null,
  material?: string | null,
  landingUrl?: string | null
) => {
  count = count || 1

  pushToDataLayer({
    event: "add_to_cart",
    addToCartCurrency: "SEK",
    addToCartValue: (price * count).toString(),
    addToCartItems: [
      {
        item_id: itemId,
        item_name: signTypeKey,
        discount: discountPercent,
        item_category: landingUrl,
        item_category_2: material,
        item_category_3: size,
        item_category_4: hangType,
        currency: "SEK",
        price: price,
        quantity: count,
      },
    ],
  })
}

export const basketItemsAddToGA4 = (order: any, landingUrl?: string | null) => {
  const orderItems: any[] = []
  let totalToCartValue = 0

  order.basketItems.map((item: any) => {
    orderItems.push({
      item_id: item.exampleId,
      item_name: item.signTypeKey,
      discount: item.discountPercentage,
      item_category: landingUrl,
      item_category_2: item.materialNameForGtm,
      item_category_3: item.sizeDisplay,
      item_category_4: item.hangType,
      currency: "SEK",
      price: item.priceSek,
      quantity: item.totalCount,
    })
    totalToCartValue += item.priceSek * item.totalCount
  })

  pushToDataLayer({
    event: "add_to_cart",
    addToCartCurrency: "SEK",
    addToCartValue: totalToCartValue.toString(),
    addToCartItems: orderItems,
  })
}

export interface InputErrors {
  [key: string]: string
}

export interface SizeApplyArgs {
  availableWidth: number
  availableHeight: number
  elements: Elements
  rects: ElementRects
}

export const addErrorMsgOnInput = (element: HTMLElement, text: string) => {
  element.insertAdjacentHTML(
    "afterend",
    `<div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="h-5 w-5 text-red-500"><path clip-rule="evenodd" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm-1 14a1 1 0 102 0 1 1 0 00-2 0zm0-3a1 1 0 102 0V8a1 1 0 10-2 0z" fill="currentColor" fill-rule="evenodd"></path></svg></div>`
  )

  if (element.parentElement) {
    element.parentElement.classList.add("error")
    element.parentElement.insertAdjacentHTML("afterend", `<div class="mt-2 text-sm text-red-600">${text}</div>`)
  }
}

export const transitionTime = (time: number) => {
  if (env.TEST) {
    return 0
  } else {
    return time
  }
}

export const equalNumbers = (nr1: string, nr2: string): boolean => {
  const float1 = parseFloat(nr1)
  const float2 = parseFloat(nr2)

  if (isNaN(float1) && isNaN(float2)) {
    return true
  }

  return float1 == float2
}

export const metaNameSign = (signType: string): boolean => {
  return ["name_sign", "color_badge", "etched_aluminium_sign"].includes(signType)
}

export const metaPlasticSign = (signType: string): boolean => {
  return ["plastic_sign", "forex_sign", "solid_plastic_sign"].includes(signType)
}

export const sameDate = (date1: Date, date2: Date) => {
  return (
    date1.getDate() == date2.getDate() &&
    date1.getMonth() == date2.getMonth() &&
    date1.getFullYear() == date2.getFullYear()
  )
}

export default {
  isBlank,
  objEmpty,
  escapeHtml,
  keys,
  snakeCase,
  repeatElement,
  stringifyObj,
  isPresent,
  waitUntil,
  clamp,
  raf,
  cssTime,
  isTablet,
  isMobile,
  formatMonth,
  dig,
  formatBytes,
  capitalize,
  round,
  toggleScroll,
  pushToDataLayer,
  pushConsentToDataLayer,
  basketAddToGA4,
  addErrorMsgOnInput,
  transitionTime,
  equalNumbers,
  metaNameSign,
  metaPlasticSign,
  sameDate,
}
