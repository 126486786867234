import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["target"]

  declare targetTarget: HTMLElement
  declare hasTargetTarget: boolean

  toggle(e?: Event) {
    e?.preventDefault()

    if (this.hasTargetTarget) {
      this.targetTarget.classList.toggle("hidden")
    }
  }
}
